import { getCookie, setCookie } from './components/cookie';
import { ajaxDebug } from './components/ajax-debug-log';
// import qs from 'qs';
import 'jquery-validation/dist/jquery.validate';

// import axios from 'axios/dist/axios';
import md5 from 'blueimp-md5/js/md5';

jQuery(document).ready(($) => {

  let formSent = false;

  const activateSelectedInputs = () => {
    $('.js-signup-wrap .input-group--2 input').each(function() {
      const that = $(this);

      if ( that.val() != '' ) {
        that.siblings('label').addClass('is-active');
      }
    });
  }

  activateSelectedInputs();


  let fbEventId = '';
  //user's role
  $(document).on('click', '.js-feat-box', e => {
    activateSelectedInputs();

    const that = $(e.currentTarget);
    const role = that.attr('data-role');
    $('.content-section-1--step-1').addClass('hidden');
    $('.content-section-1--step-2').removeClass('hidden');
    $('.content-section-3--step-1').addClass('hidden');
    $('.content-section-3--step-2').removeClass('hidden');

    $('.js-role-switcher').removeClass('is-active');
    $(`.js-role-switcher[data-role="${role}"]`).addClass('is-active');

    $('.js-signup input[name="role"]').val(role);

    if ( role == 'investor' ) {
      $('.js-signup-wrap').addClass('hidden');
      $('.js-investor-calendar').removeClass('hidden');
    } else {
      $('.js-signup-wrap').removeClass('hidden');
      $('.js-investor-calendar').addClass('hidden');
    }


    if ( role == 'owner' ) {
      $('.js-practice-name').removeClass('hidden');
      $('.js-employee-role').addClass('hidden').removeClass('flex');
      $('.js-phone-group').removeClass('hidden');
      $('label[for="email"]').html('Email <span class="req">*</span>');
      $('.checkbox-group--text-messages').removeClass('hidden');
    } else {
      $('.js-practice-name').addClass('hidden');
      $('.js-employee-role').removeClass('hidden').addClass('flex');
      $('.js-phone-group').addClass('hidden');
      $('label[for="email"]').html('Work Email <span class="req">*</span>');
      $('.checkbox-group--text-messages').addClass('hidden');
    }

    if ( $(window).width() < 992 ) {
      const scrollTop = $('.content-section-1--step-2').length ? $('.content-section-1--step-2').offset().top - 50 : $('.content-section-3--step-2').offset().top - 50;
      $('html, body').animate({
        scrollTop: scrollTop,
      }, 0);
    }
  });

  $(document).on('click', '.js-role-switcher', e => {
    activateSelectedInputs();

    const that = $(e.currentTarget);
    const role = that.attr('data-role');

    $('.js-role-switcher').removeClass('is-active');
    that.addClass('is-active');

    $('.js-signup input[name="role"]').val(role);

    if ( role == 'investor' ) {
      $('.js-signup-wrap').addClass('hidden');
      $('.js-investor-calendar').removeClass('hidden');
    } else {
      $('.js-signup-wrap').removeClass('hidden');
      $('.js-investor-calendar').addClass('hidden');
    }


    if ( role == 'owner' ) {
      $('.js-practice-name').removeClass('hidden');
      $('.js-employee-role').addClass('hidden').removeClass('flex');
      $('.js-phone-group').removeClass('hidden');
      $('label[for="email"]').html('Email <span class="req">*</span>');
      $('.checkbox-group--text-messages').removeClass('hidden');
    } else {
      $('.js-practice-name').addClass('hidden');
      $('.js-employee-role').removeClass('hidden').addClass('flex');
      $('.js-phone-group').addClass('hidden');
      $('label[for="email"]').html('Work Email <span class="req">*</span>');
      $('.checkbox-group--text-messages').addClass('hidden');
    }

    if ( $(window).width() < 992 ) {
      const scrollTop = $('.content-section-1--step-2').length ? $('.content-section-1--step-2').offset().top - 50 : $('.content-section-3--step-2').offset().top - 50;
      $('html, body').animate({
        scrollTop: scrollTop,
      }, 0);
    }
  });

  //back to prev step
  $(document).on('click', '.js-form-back', () => {
    $('.content-section-1--step-1').removeClass('hidden');
    $('.content-section-1--step-2').addClass('hidden');
    $('.content-section-3--step-1').removeClass('hidden');
    $('.content-section-3--step-2').addClass('hidden');
  });

  //validation
  if ( $('.js-signup').length ) {
    $('.js-signup').each(function() {
      $(this).validate({
        f_name: {
          required: true,
        },
        l_name: {
          required: true,
        },
        city: {
          required: true,
        },
        phone: {
          required: true,
        },
        acceptance: {
          required: true,
        },
        email: {
          required: true,
          email: true,
          regex: /^\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i,
        },
      });
    });
  }

  //create WP user & submit data to HS
  $('.js-signup').on('submit', (e) => {
    const that = $(e.currentTarget);
    const formValidation = that.valid();

    if ( formValidation != true ) {
      return;
    }

    that.find('.error-box').addClass('hidden');
    that.find('button').addClass('is-loading').attr('disabled', 'true');
    let regLink = '';
    let country = '';
    let regionXhr = '';
    let zip = '';
    const hutkCookie = getCookie('hubspotutk');
    const clarityUserId = getCookie('_clck') ? getCookie('_clck') : '';
    const f_name = that.find('input[name="f_name"]').val();
    const l_name = that.find('input[name="l_name"]').val();
    const email = that.find('input[name="email"]').val();
    const role = that.find('input[name="role"]').val();
    let acceptance = '';
    let acceptance2 = '';
    const phone_number = that.find('input[name="phone"]').val() ? that.find('input[name="phone"]').val() : '';
    const city = that.find('input[name="city"]').val() ? that.find('input[name="city"]').val() : '';
    const region = that.find('input[name="region"]').val() ? that.find('input[name="region"]').val() : '';
    const ref = that.find('input[name="ref"]').val() ? that.find('input[name="ref"]').val() : '';
    const practiceName = that.find('input[name="practice_name"]').val() ? that.find('input[name="practice_name"]').val() : '';
    const eRole = that.find('select[name="e_role"]').val() ? that.find('select[name="e_role"]').val() : '';
    const experience = that.find('select[name="experience"]').val() ? that.find('select[name="experience"]').val() : '';
    const security = that.find('input[name="signonsecurity"]').val();

    //popup fields
    const stepsForm = that.hasClass('js-popup-signup-steps') ? true : false;
    const revenue = that.hasClass('js-popup-signup-steps') ? that.find('#revenue').val() : '';
    const ebitda = that.hasClass('js-popup-signup-steps') ? that.find('#ebitda').val() : '';
    const fTimeDoctors = that.hasClass('js-popup-signup-steps') ? that.find('#f-time-doctors').val() : '';
    const examRooms = that.hasClass('js-popup-signup-steps') ? that.find('#exam-rooms').val() : '';
    const multipleToday = that.hasClass('js-popup-signup-steps') ? that.find('#multiple-today').val() : '';

    let hsForm = true;

    if ( $('.js-popup-signup-steps').length ) {
      console.log('setCookie');
      setCookie('revenue', revenue, 365);
      setCookie('ebitda', ebitda, 365);
      setCookie('doctors', fTimeDoctors, 365);
      setCookie('rooms', examRooms, 365);
      setCookie('multiple', multipleToday, 365);
    }

    if ( that.find('input[name="form_acceptance"]').is(':checked') ) {
      acceptance = 'Yes';
    } else {
      acceptance = 'No';
    }

    if ( that.find('input[name="accept_text_messages"]').is(':checked') ) {
      acceptance2 = 'Yes';
    } else {
      acceptance2 = 'No';
    }

    $.when( $.ajax({
      url: '/wp-admin/admin-ajax.php',
      type: 'post',
      dataType: 'JSON',
      data: {
        'action': 'ajaxregister',
        'email': email,
        'f_name': f_name,
        'l_name': l_name,
        'e_role': eRole,
        'ref': ref,
        'role': role,
        'phone_number': phone_number,
        'city': city,
        'years_of_experience': experience,
        'practice_name': practiceName,
        'region': region,
        'steps_form': stepsForm,
        'revenue': revenue,
        'ebitda': ebitda,
        'f_time_doctors': fTimeDoctors,
        'exam_rooms': examRooms,
        'multiple_today': multipleToday,
        'security': security,
      },
    }))
    .then(
      res => {
        // console.log(res);
        if ( res.registration == false ) {
          that.find('button').removeClass('is-loading').removeAttr('disabled');
          that.find('.error-box').removeClass('hidden');
          that.find('.error-box p').text(res.message);
          const errorMsg = `time: ${new Date().toUTCString()}, email: ${email}, ${JSON.stringify(res)}`;
          ajaxDebug(errorMsg);
          console.log(errorMsg);
          return;
        } else {
          fbEventId = res.fb_event_id;
          regLink = decodeURI(res.password_reset_link);

          formSent = true;

          const fieldsArray = [
            {
              "name": "firstname",
              "value": f_name,
            },
            {
              "name": "lastname",
              "value": l_name,
            },
            {
              "name": "email",
              "value": email,
            },
            {
              "name": "phone",
              "value": phone_number,
            },
            {
              "name": "city",
              "value": city,
            },
            {
              "name": "state",
              "value": region,
            },
            {
              "name": "clarity_user_id",
              "value": clarityUserId,
            },
            {
              "name": "role",
              "value": role,
            },
            {
              "name": "practice_name",
              "value": practiceName,
            },
            {
              "name": "years_of_experience",
              "value": experience,
            },
            {
              "name": "select_your_role",
              "value": eRole,
            },
            {
              "name": "experiment___unique_link",
              "value": regLink,
            },
            {
              "name": "experiment___referral_link",
              "value": ref,
            },
            {
              "name": "form_acceptance",
              "value": acceptance,
            },
            {
              "name": "accept_text_messages",
              "value": acceptance2,
            },
            {
              "name": "user_referral_link",
              "value": `https://galaxyvets.com/?ref=${md5(email)}`,
            },
          ];

          let contextObj = {
            "pageUri": window.location.href,
            "pageName": $('head title').text(),
            "ipAddress": window.userIP ? window.userIP : undefined,
          }

          if ( hutkCookie ) {
            contextObj.hutk = hutkCookie;
          }

          $.when( $.ajax({
              url: role == 'owner' ? 'https://api.hsforms.com/submissions/v3/integration/submit/20247445/947d971c-3194-489c-947f-fba0cdd0be5f' : 'https://api.hsforms.com/submissions/v3/integration/submit/20247445/7c70ef26-2bbf-41a5-8f0c-21032c40a0fa',
              method: 'post',
              contentType: 'application/json',
              // dataType: 'JSON',
              data: JSON.stringify({
                "submittedAt": Date.now(),
                "fields": fieldsArray,
                "context": contextObj,
              }),
            })
            .catch(
              e => {
                console.log(e);
                that.find('button').removeClass('is-loading').removeAttr('disabled');
                if ( e.status != 400 ) {
                  that.find('.error-box').removeClass('hidden');
                  that.find('.error-box p').text(e.responseJSON.message);
                }

                if ( e.responseJSON.errors[0].message == "Error in 'fields.email'. Invalid email address" ) {
                  that.find('.error-box').removeClass('hidden');
                  that.find('.error-box p').text('We tried to send you an email, and it bounced back. Can you please enter a new email address?');
                }

                const errorMsg = `time: ${new Date().toUTCString()}, email: ${email}, ${JSON.stringify(e)}`;
                ajaxDebug(errorMsg);
                console.log(errorMsg);

                if ( e.status == 400 ) {
                  hsForm = false;
                }
              }
            )

            .then(
              () => {
                // console.log('hsForm', hsForm);
                that.find('button').removeClass('is-loading').removeAttr('disabled');

              //submit one more form without timestamp in case of error

                // console.log('regLink', regLink);
                if ( hsForm == true ) {

                  // console.log('yup', regLink);

                  that.find('input').val('');
                  that.find('input[type="checkbox]').prop('checked', false);

                  // console.log('yup2', regLink);

                  if ( window.dataLayer ) {
                    window.dataLayer.push({
                      'event': 'signup',
                      'role': role,
                      'email': email,
                      'first_name': f_name,
                      'last_name': l_name,
                      'fb_event_id': fbEventId,
                      'phone_number': phone_number,
                      'country': country,
                      'zip': zip,
                      'city': city,
                      'region': regionXhr,
                      'status': 'registered',
                      'ip': window.userIP,
                      'user_agent': navigator.userAgent,
                    });
                  }

                  console.log('track');

                  setTimeout(() => {
                    if ( $('.js-signup-redirect').length ) {
                      window.location = $('.js-signup-redirect').attr('data-url');
                    } else {
                      window.location = regLink;
                    }
                  }, 100);
                }
              }
            )
          );
        }
      },
      e => {
        console.log(e);
        that.find('button').removeClass('is-loading').removeAttr('disabled');
        that.find('.error-box').removeClass('hidden');
        that.find('.error-box p').text(e.message);
        const errorMsg = `time: ${new Date().toUTCString()}, email: ${email}, ${JSON.stringify(e)}`;
        ajaxDebug(errorMsg);
        console.log(errorMsg);
      }
    );

    return false;
  });

  if ( $('.js-popup-signup-steps').length ) {
    $('.js-popup-signup-steps').validate({
      email: {
        required: true,
        email: true,
        regex: /^\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i,
      },
    });

    let lastFocusedInput = '';

    $(document).on('focus', '.js-popup-signup-steps input', e => {
      const that = $(e.currentTarget);
      lastFocusedInput = that.closest('.input-group').find('label.text-base').text();
    });

    window.addEventListener('beforeunload', () => {
      if ( window.dataLayer && !formSent && lastFocusedInput ) {
        let clarityUserId = getCookie('_clck') ? getCookie('_clck') : '';

        window.dataLayer.push({
          'event': 'last_field_before_close',
          'input_label': lastFocusedInput,
          'id': clarityUserId,
        });
      }
    });
  }

  $(document).on('click', '.js-signup-step-btn', e => {
    const that = $(e.currentTarget);
    const thatStep = that.attr('data-step');
    const formValidation = $('.js-popup-signup-steps').valid();

    if ( formValidation == true || that.hasClass('js-signup-step-btn--back') ) {
      $('.popup-step').addClass('hidden');
      $(`.popup-step[data-id="${thatStep}"]`).removeClass('hidden');
    }
  });
});
